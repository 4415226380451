import styled from "styled-components/macro";
import { bodyBgColor } from "../../config";

const buttonHeight = 30;
const formMargin = 25;

export const SettingsContainer = styled.div`
  display: flex;
  padding-bottom: 60px;
  align-items: center;
  flex-direction: column;
  background-color: ${bodyBgColor};
  width: 96vw;
  margin: 0 auto;

  h3 {
    text-align: center;
    font-size: 72px;
    margin-top: 15px;
  }

  .formField {
    margin-top: ${formMargin}px;
    min-width: 300px;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pickerWrapper {
    margin-top: ${formMargin}px;

    .pickerLabelContainer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .preview {
        height: 30px;
        width: 30px;
        border: 1px solid #222;
      }
    }

    .twitter-picker {
      margin: 10px auto;

      input {
        display: none;
      }

      div {
        div:first {
        }
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      border: 2px solid #222;
      height: ${buttonHeight}px;
      font-size: ${Math.floor(buttonHeight * 0.5)}px;
      font-weight: bold;
      color: ${bodyBgColor};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      width: 45%;

      &.cancel {
        background-color: red;
      }

      &.save {
        background-color: blue;
      }
    }
  }

  .overlayLabel,
  .soundsLabel {
    display: block;
    margin-top: ${formMargin}px;
    margin-bottom: 10px;
  }

  .overlaySelectorRow {
    display: flex;
    flex-flow: row nowrap;

    .overlaySelectorWrapper {
      margin-right: 20px;
      padding: 5px;
      box-sizing: border-box;
      border: 2px solid ${bodyBgColor};

      &.selected {
        border: 2px solid #222;
      }
    }
  }

  .soundSelectorColumn {
    display: flex;
    flex-direction: column;

    .soundSelectorWrapper {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid #222;

      &.selected {
        border: 2px solid black;
        background-color: #eee;
      }
    }
  }
`;

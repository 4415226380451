import React from "react";
import { ButtonContainer } from "./ButtonSection.styles";

interface Props {
  allowButtonClick: boolean;
  isRunning: boolean;
  pizzaSize: number;
  secondsLeft: number;
  totalSeconds: number;
  startTimer: () => void;
  pauseTimer: () => void;
  resetTimer: () => void;
}

function CurrentButtons(props: Props) {
  const { isRunning, secondsLeft, totalSeconds, startTimer, pauseTimer, resetTimer } = props;

  if (isRunning) {
    return (
      <button className="fullWidth pause" onClick={pauseTimer}>
        PAUSE
      </button>
    );
  }

  if (secondsLeft <= 0) {
    return (
      <button className="fullWidth reset" onClick={resetTimer}>
        RESET
      </button>
    );
  }

  if (secondsLeft === totalSeconds) {
    return (
      <button className="fullWidth start" onClick={startTimer}>
        START
      </button>
    );
  }

  return (
    <>
      <button className="halfWidth start" onClick={startTimer}>
        START
      </button>
      <button className="halfWidth reset" onClick={resetTimer}>
        RESET
      </button>
    </>
  );
}

function ButtonSection(props: Props) {
  return (
    <ButtonContainer pizzaSize={props.pizzaSize} allowButtonClick={props.allowButtonClick}>
      <CurrentButtons {...props} />
    </ButtonContainer>
  );
}

export default ButtonSection;

import styled from "styled-components";
import { bodyBgColor, verticalBreakpoint } from "../../config";

const buttonHeight = window.innerHeight > verticalBreakpoint ? 55 : 30;

export const ButtonContainer = styled.div<{ pizzaSize: number; allowButtonClick: boolean }>`
  width: ${(p) => Math.floor(p.pizzaSize * 1.2)}px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 60px;

  button {
    border: 2px solid #222;
    height: ${buttonHeight}px;
    font-size: ${Math.floor(buttonHeight * 0.6)}px;
    font-weight: bold;
    color: ${bodyBgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: ${(p) => (p.allowButtonClick ? 1.0 : 0.3)};
    text-decoration: none;

    &.fullWidth {
      width: 100%;
    }

    &.halfWidth {
      width: 45%;
    }

    &.start {
      background-color: green;
    }

    &.pause {
      background-color: red;
    }

    &.reset {
      background-color: blue;
    }
  }
`;

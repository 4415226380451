export const bodyBgColor = "#f7f7f7";
export const localstorageKey = "bdtimerstorage";
export const defaultTimerTitle = "My Timer";
export const verticalBreakpoint = 700;

export interface LocalStorageModel {
  totalSeconds: number;
  timerTitle: string;
  sliceColor: string;
  overlay: Overlay;
  sound: Sounds;
}

export enum Overlay {
  CIRCLE = "CIRCLE",
  HEART = "HEART",
  STAR = "STAR",
}

export const overlays: Record<Overlay, { url: string }> = {
  [Overlay.CIRCLE]: {
    url: "/circle.png",
  },
  [Overlay.HEART]: {
    url: "/heart.png",
  },
  [Overlay.STAR]: {
    url: "/star.png",
  },
};

export enum Sounds {
  NONE = "NONE",
  DING1 = "DING1",
  DING2 = "DING2",
  DING3 = "DING3",
}

export const sounds: Record<Sounds, { url: string }> = {
  [Sounds.NONE]: {
    url: "/notfound.wav",
  },
  [Sounds.DING1]: {
    url: "/ding.wav",
  },
  [Sounds.DING2]: {
    url: "/ding2.wav",
  },
  [Sounds.DING3]: {
    url: "/ding3.wav",
  },
};

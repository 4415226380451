import styled from "styled-components/macro";
import { bodyBgColor, verticalBreakpoint } from "../../config";

export const AppContainer = styled.div<{ pizzaSize: number }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: ${bodyBgColor};
  height: 100vh;
  width: 96vw;
  margin: 0 auto;
  overflow: hidden;

  h3 {
    width: ${(p) => p.pizzaSize}px;
    text-align: center;
    font-size: 72px;
    margin-top: 15px;

    @media (max-height: ${verticalBreakpoint}px) {
      font-size: 48px;
      margin-bottom: 15px;
    }
  }
`;

export const PizzaWrapper = styled.div<{ bgColor: string; pizzaSize: number }>`
  position: relative;

  .pizzaContainer {
    height: ${(p) => p.pizzaSize}px;

    .pizzaBackground {
      background-color: ${(p) => p.bgColor};
      position: absolute;
      width: ${(p) => p.pizzaSize}px;
      height: ${(p) => p.pizzaSize}px;
      -moz-border-radius: ${(p) => p.pizzaSize / 2}px;
      -webkit-border-radius: ${(p) => p.pizzaSize / 2}px;
      -o-border-radius: ${(p) => p.pizzaSize / 2}px;
      border-radius: ${(p) => p.pizzaSize / 2}px;
    }

    .overlay {
      z-index: 1;
      width: ${(p) => Math.floor(p.pizzaSize)}px;
      height: ${(p) => Math.floor(p.pizzaSize)}px;
      position: absolute;
      background-color: transparent;
    }
  }
`;

export const SliceContainer = styled.div<{
  rotation: number;
  sliceColor: string;
  isLeftHalf: boolean;
  pizzaSize: number;
}>`
  position: absolute;
  width: ${(p) => p.pizzaSize}px;
  height: ${(p) => p.pizzaSize}px;
  -moz-border-radius: ${(p) => p.pizzaSize / 2}px;
  -webkit-border-radius: ${(p) => p.pizzaSize / 2}px;
  -o-border-radius: ${(p) => p.pizzaSize / 2}px;
  border-radius: ${(p) => p.pizzaSize / 2}px;
  clip: rect(0px, ${(p) => p.pizzaSize}px, ${(p) => p.pizzaSize}px, ${(p) => p.pizzaSize / 2}px);
  ${(p) => {
    if (!p.isLeftHalf) {
      return "";
    }
    return `
      -webkit-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      -o-transform:rotate(180deg);
      transform:rotate(180deg);
    `;
  }}

  .slice {
    position: absolute;
    width: ${(p) => p.pizzaSize}px;
    height: ${(p) => p.pizzaSize}px;
    -moz-border-radius: ${(p) => p.pizzaSize / 2}px;
    -webkit-border-radius: ${(p) => p.pizzaSize / 2}px;
    -o-border-radius: ${(p) => p.pizzaSize / 2}px;
    border-radius: ${(p) => p.pizzaSize / 2}px;
    clip: rect(0px, ${(p) => p.pizzaSize / 2}px, ${(p) => p.pizzaSize}px, 0px);
    background-color: ${(p) => p.sliceColor};
    -webkit-transform: ${(p) => `rotate(${p.rotation}deg)`};
    -moz-transform: ${(p) => `rotate(${p.rotation}deg)`};
    -o-transform: ${(p) => `rotate(${p.rotation}deg)`};
    transform: ${(p) => `rotate(${p.rotation}deg)`};
  }
`;

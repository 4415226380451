import { useState } from "react";
import {
  defaultTimerTitle,
  localstorageKey,
  LocalStorageModel,
  Overlay,
  overlays,
  sounds,
  Sounds,
} from "../../config";
import { SettingsContainer } from "./SettingsPage.styles";
import { TwitterPicker } from "react-color";

interface Props {
  title: string;
  totalSeconds: number;
  sliceColor: string;
  overlay: Overlay;
  sound: Sounds;
  setTitle: (str: string) => void;
  setTotalSeconds: (num: number) => void;
  setSliceColor: (str: string) => void;
  setOverlay: (o: Overlay) => void;
  setSound: (s: Sounds) => void;
  setShowSettings: (bool: boolean) => void;
  setSecondsLeft: (num: number) => void;
}

function SettingsPage(props: Props) {
  const {
    title,
    totalSeconds,
    sliceColor,
    overlay,
    sound,
    setTitle,
    setTotalSeconds,
    setSliceColor,
    setOverlay,
    setSound,
    setShowSettings,
    setSecondsLeft,
  } = props;
  const [tempMinutes, setTempMinutes] = useState((totalSeconds / 60).toString());
  const [tempTitle, setTempTitle] = useState(title);
  const [tempColor, setTempColor] = useState(sliceColor);
  const [tempOverlay, setTempOverlay] = useState(overlay);
  const [tempSound, setTempSound] = useState(sound);
  const [error, setError] = useState("");

  const cancel = () => setShowSettings(false);

  const saveSettings = () => {
    if (!tempMinutes || Number(tempMinutes) <= 0) {
      setError("Minutes field is invalid");
      return;
    }

    const tempTotalSeconds = Number(tempMinutes) * 60;
    const newTitle = tempTitle || defaultTimerTitle;

    if (
      tempTotalSeconds === totalSeconds &&
      newTitle === title &&
      tempColor === sliceColor &&
      tempOverlay === overlay &&
      tempSound === sound
    ) {
      setShowSettings(false);
      return;
    }

    setTotalSeconds(tempTotalSeconds);
    setSecondsLeft(tempTotalSeconds);
    setSliceColor(tempColor);
    setOverlay(tempOverlay);
    setSound(tempSound);
    setTitle(newTitle);
    const storageVal: LocalStorageModel = {
      timerTitle: newTitle,
      totalSeconds,
      sliceColor: tempColor,
      overlay: tempOverlay,
      sound: tempSound,
    };

    window.localStorage.setItem(localstorageKey, JSON.stringify(storageVal));
    setShowSettings(false);
  };

  const handleColorChange = (color: { hex: string }) => {
    setTempColor(color.hex);
  };

  const overlaySelectors = Object.entries(overlays).map(([key, val]) => (
    <div
      key={key}
      className={`overlaySelectorWrapper${tempOverlay === key ? " selected" : ""}`}
      onClick={() => setTempOverlay(key as Overlay)}
    >
      <img src={val.url} alt={key} height={40} width={40} />
    </div>
  ));

  const handleSoundSelect = (sound: Sounds) => {
    if (sound !== Sounds.NONE) {
      const ding = new Audio(sounds[sound].url);
      ding.play();
    }
    setTempSound(sound);
  };

  const soundSelectors = Object.entries(sounds).map(([key, val]) => {
    return (
      <div
        key={key}
        className={`soundSelectorWrapper ${tempSound === key ? " selected" : ""}`}
        onClick={() => handleSoundSelect(key as Sounds)}
      >
        {key[0] + key.slice(1).toLowerCase()}
      </div>
    );
  });

  return (
    <SettingsContainer>
      <h1>Settings</h1>
      <div className="formWrapper">
        <div className="formField">
          <label htmlFor="minutes">Minutes</label>
          <input
            id="minutes"
            type="number"
            value={tempMinutes}
            onChange={(e) => setTempMinutes(e.currentTarget.value)}
          />
        </div>
        <div className="formField">
          <label htmlFor="timerTitle">Timer Name</label>
          <input
            id="timerTitle"
            type="text"
            value={tempTitle}
            onChange={(e) => setTempTitle(e.currentTarget.value)}
          />
        </div>

        <div className="pickerWrapper">
          <div className="pickerLabelContainer">
            <label>Countdown Color</label>
            <div className="preview" style={{ backgroundColor: tempColor }}></div>
          </div>
          <TwitterPicker
            triangle="hide"
            width="300"
            colors={[
              "#FF0000", // red
              "#FFA500", // orange
              "#FFD700", // yellow (gold)
              "#008000", // green
              "#0000FF", // blue
              "#87CEEB", // skyBlue
              "#4B0082", // indigo
              "#800080", // purple
              "#FFC0CB", // pink
              "#FF1493", // deepPink
              "#222222", // black
            ]}
            color={tempColor}
            onChange={handleColorChange}
          />
        </div>

        <label className="overlayLabel">Countdown Shape</label>
        <div className="overlaySelectorRow">{overlaySelectors}</div>

        <label className="soundsLabel">Sound</label>
        <div className="soundSelectorColumn">{soundSelectors}</div>

        <div className="buttonContainer">
          <button onClick={saveSettings} className="save">
            SAVE
          </button>
          <button onClick={cancel} className="cancel">
            CANCEL
          </button>
        </div>
        <div className="error">{error}</div>
      </div>
    </SettingsContainer>
  );
}

export default SettingsPage;

import { useEffect, useState } from "react";
import {
  defaultTimerTitle,
  localstorageKey,
  LocalStorageModel,
  Overlay,
  overlays,
  sounds,
  Sounds,
} from "../../config";
import useInterval from "../../utils/useInterval";
import ButtonSection from "../ButtonSection";
import SettingsPage from "../SettingsPage";
import { AppContainer, PizzaWrapper, SliceContainer } from "./App.styles";

const savedSettings = window.localStorage.getItem(localstorageKey);

function App() {
  let initialSeconds = 60;
  let initialColor = "#FFD700";
  let initialTitle = defaultTimerTitle;
  let initialOverlay = Overlay.STAR;
  let initalSound = Sounds.DING1;

  if (savedSettings) {
    try {
      const parsedSettings: LocalStorageModel = JSON.parse(savedSettings);
      if (parsedSettings.totalSeconds && parsedSettings.totalSeconds % 60 === 0) {
        initialSeconds = parsedSettings.totalSeconds;
      }
      if (parsedSettings.timerTitle) {
        initialTitle = parsedSettings.timerTitle;
      }
      if (parsedSettings.sliceColor) {
        initialColor = parsedSettings.sliceColor;
      }
      if (parsedSettings.overlay && parsedSettings.overlay in Overlay) {
        initialOverlay = parsedSettings.overlay;
      }
      if (parsedSettings.sound && parsedSettings.sound in Sounds) {
        initalSound = parsedSettings.sound;
      }
    } catch (e) {
      console.log(e);
      // do nothing, use default settings
    }
  }

  const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);
  const [sliceColor, setSliceColor] = useState(initialColor);
  const [title, setTitle] = useState(initialTitle);
  const [sound, setSound] = useState(initalSound);
  const [isRunning, setIsRunning] = useState(false);
  const [allowButtonClick, setAllowButtonClick] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [overlay, setOverlay] = useState<Overlay>(initialOverlay);

  const toggleButtons = () => {
    setAllowButtonClick(false);
    setTimeout(() => setAllowButtonClick(true), 2000);
  };

  const startTimer = () => {
    if (!allowButtonClick) {
      return;
    }

    toggleButtons();
    setIsRunning(true);
  };
  const pauseTimer = () => {
    if (!allowButtonClick) {
      return;
    }

    toggleButtons();
    setIsRunning(false);
  };
  const resetTimer = () => {
    if (!allowButtonClick) {
      return;
    }
    setSecondsLeft(totalSeconds);
  };

  useInterval(
    () => {
      setSecondsLeft(secondsLeft - 1);
    },
    !isRunning || secondsLeft <= 0 ? null : 1000
  );

  useEffect(() => {
    if (secondsLeft <= 0) {
      if (sound !== Sounds.NONE && sounds[sound]?.url) {
        const ding = new Audio(sounds[sound].url);
        ding.play();
      }
      setIsRunning(false);
    }
  }, [secondsLeft]);

  const totalRotationDegrees = Math.floor(360 * (secondsLeft / totalSeconds));
  const leftRotation = totalRotationDegrees < 180 ? 0 : totalRotationDegrees - 180;
  const rightRotation = totalRotationDegrees > 180 ? 180 : totalRotationDegrees;

  const pizzaSize = (() => {
    const height = Math.floor(window.innerHeight * 0.4);
    const width = Math.floor(window.innerWidth * 0.9);
    // turn it into an even number so we don't have half-pixels in the css
    return Math.floor(Math.min(height, width) / 2) * 2;
  })();

  const timeRemaining = (seconds: number): string => {
    if (seconds < 60) {
      return seconds.toString();
    }
    const pad = (num: number): string => (num >= 10 ? num.toString() : `0${num}`);
    return `${Math.floor(seconds / 60)}:${pad(seconds % 60)}`;
  };

  const displayTime = timeRemaining(secondsLeft);

  if (showSettings) {
    return (
      <SettingsPage
        title={title}
        totalSeconds={totalSeconds}
        sliceColor={sliceColor}
        overlay={overlay}
        sound={sound}
        setSliceColor={setSliceColor}
        setTitle={setTitle}
        setOverlay={setOverlay}
        setSound={setSound}
        setTotalSeconds={setTotalSeconds}
        setShowSettings={setShowSettings}
        setSecondsLeft={setSecondsLeft}
      />
    );
  }

  return (
    <AppContainer pizzaSize={pizzaSize}>
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <h1>{title}</h1>
        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <img
            src="/gear.png"
            height="15"
            width="15"
            alt="settings"
            onClick={() => setShowSettings(true)}
          />
          &nbsp;&nbsp;
          <span onClick={() => setShowSettings(true)}>Settings</span>
        </div>
      </div>
      <PizzaWrapper bgColor="#EEEEEE" pizzaSize={pizzaSize}>
        <div className="pizzaContainer">
          <div className="pizzaBackground"></div>

          {/* Each SliceContainer covers half of the circle */}
          <SliceContainer
            rotation={rightRotation}
            sliceColor={sliceColor}
            isLeftHalf={false}
            pizzaSize={pizzaSize}
          >
            <div className="slice"></div>
          </SliceContainer>

          <SliceContainer
            rotation={leftRotation}
            sliceColor={sliceColor}
            isLeftHalf={true}
            pizzaSize={pizzaSize}
          >
            <div className="slice"></div>
          </SliceContainer>
          {overlay !== Overlay.CIRCLE && overlays[overlay]?.url && (
            <img src={overlays[overlay].url} className="overlay" />
          )}
        </div>
        <h3>{displayTime}</h3>
      </PizzaWrapper>
      <ButtonSection
        allowButtonClick={allowButtonClick}
        isRunning={isRunning}
        pizzaSize={pizzaSize}
        secondsLeft={secondsLeft}
        totalSeconds={totalSeconds}
        startTimer={startTimer}
        pauseTimer={pauseTimer}
        resetTimer={resetTimer}
      />
    </AppContainer>
  );
}

export default App;
